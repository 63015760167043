
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyEconomy extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly recessionGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'Recessions',
                    type: "bar",
                    x: [1932.6, 1938.1, 1945.7, 1948.9, 1953.8, 1957.7, 1960.8, 1969.9, 1974.3, 1980.5, 1982.3, 1990.7, 2001.7, 2008.5,
                        2022],
                    y: [-26.7, -18.2, -12.7, -1.7, -2.6, -3.7, -1.6, -0.6, -3.2, -2.2, -2.7, -1.4, -0.3, -5.1,
                        -25],
                    width: [3.6, 1.1, 0.7, 0.9, 0.8, 0.7, 0.8, 0.9, 1.3, 0.5, 1.3, 0.7, 0.7, 1.5, 4.0],
                    marker: {
                        color: ['hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)',
                                'hsl(0, 100%, 20%)'],
                        size: 9
                    },
                },
                {
                    name: 'GDP',
                    type: "scatter",
                    x: [1929, 
                        1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939,
                        1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949,
                        1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959,
                        1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969,
                        1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979,
                        1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
                        1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
                        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2022],
                    y: [-8.5,
                        -8.5, -6.4, -12.9, -1.2, 10.8, 8.9, 12.9, 5.1, -3.3, 8.0, 
                        8.8, 17.7, 18.9, 17.0, 8.0, -1.0, -11.6, -1.1, 4.1, -0.6, 
                        8.7, 8.0, 4.1, 4.7, -0.6, 7.1, 2.1, 2.1, -0.7, 6.9, 
                        2.6, 2.6, 6.1, 4.4, 5.8, 6.5, 6.6, 2.7, 4.9, 3.1,
                        0.2, 3.3, 5.3, 5.6, -0.5, -0.2, 5.4, 4.6, 5.5, 3.2,
                        -0.3, 2.5, -1.8, 4.6, 7.2, 4.2, 3.5, 3.5, 4.2, 3.7, 
                        1.9, -0.1, 3.5, 2.8, 4.0, 2.7, 3.8, 4.4, 4.5, 4.8,
                        4.1, 1.0, 1.7, 2.9, 3.8, 3.5, 2.9, 1.9, -0.1, -2.5,
                        2.6, 1.6, 2.2, 1.8, 2.5, 2.9, 1.6, 2.4, 2.9, -10],
                    marker: {
                        color: 'hsl(240, 100%, 50%)',
                        size: 3
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(180, 100%, 50%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly worldGdp2018for1to10 = {
            // tslint:disable
            traces: [{
                type:           'pie',
                rotation:       0,
                values:         [20544343, 13608152, 4971323, 3947620, 2855297,
                                 2777535, 2718732, 2083864, 1868626, 1713342],
                marker:         { },
                labels:         ['United States', 'China', 'Japan', 'Germany', 'UK',
                                 'France', 'India', 'Italy', 'Brazil', 'Canada'],
                textinfo:       "label+value",
                hoverinfo:      "label+value",
                textposition:   "outside",
                automargin:     true,
                showlegend:     false
            }],
            // tslint:enable
        };

        private readonly worldGdp2018for11to20 = {
            // tslint:disable
            traces: [{
                type:           'pie',
                rotation:       0,
                values:         [1657555, 1619424, 1433904, 1419042, 1220699,
                                 1042173, 913658, 786522, 771350, 705140],
                marker:         { },
                labels:         ['Russia', 'Korea', 'Australia', 'Spain', 'Mexico',
                                 'Indonesia', 'Netherlands', 'Saudi Arabia', 'Turkey', 'Switzerland'],
                textinfo:       "label+value",
                hoverinfo:      "label+value",
                textposition:   "outside",
                automargin:     true,
                showlegend:     false
            }],
            // tslint:enable
        };

        private readonly taxationGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'UK',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  49,   63,   75,   80,   83,   84,   86,   87,   87,   86,   86],
                    marker: {
                        color: 'hsl(0, 100%, 50%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(0, 100%, 25%)',
                        shape: 'spline',
                        width: 5
                    },
                },
                {
                    name: 'Greece',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [ 109,  127,  146,  172,  159,  177,  179,  176,  179,  176,  181],
                    marker: {
                        color: 'hsl(280, 100%, 40%)',
                        size: 7
                    },
                    line: {
                        color: 'hsl(280, 100%, 25%)',
                        shape: 'spline',
                        width: 4
                    },
                }, 
                {
                    name: 'France',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  69,   83,   85,   88,   91,   94,   95,   96,   98,   98,   98],
                    marker: {
                        color: 'hsl(320, 100%, 40%)',
                        size: 5
                    },
                    line: {
                        color: 'hsl(320, 100%, 25%)',
                        shape: 'spline',
                        width: 3
                    },
                },
                {
                    name: 'Ireland',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  42,   62,   86,  111,  120,  120,  104,   77,   74,   68,   64],
                    marker: {
                        color: 'hsl(40, 100%, 40%)',
                        size: 5
                    },
                    line: {
                        color: 'hsl(40, 100%, 25%)',
                        shape: 'spline',
                        width: 2
                    },
                },
                {
                    name: 'Germany',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  66,   73,   83,   80,   82,   79,   76,   72,   70,   66,   62],
                    marker: {
                        color: 'hsl(80, 100%, 40%)',
                        size: 4
                    },
                    line: {
                        color: 'hsl(80, 100%, 25%)',
                        shape: 'spline',
                        width: 2
                    },
                },
                {
                    name: 'Poland',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  46,   49,   53,   54,   54,   56,   50,   52,   54,   51,   49],
                    marker: {
                        color: 'hsl(120, 100%, 40%)',
                        size: 3
                    },
                    line: {
                        color: 'hsl(120, 100%, 25%)',
                        shape: 'spline',
                        width: 2
                    },
                },
                {
                    name: 'Sweden',
                    type: "scatter",
                    x: [2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018],
                    y: [  38,   41,   39,   37,   38,   41,   45,   44,   43,   41,   39],
                    marker: {
                        color: 'hsl(160, 100%, 40%)',
                        size: 3
                    },
                    line: {
                        color: 'hsl(160, 100%, 25%)',
                        shape: 'spline',
                        width: 1
                    },
                },
            ],
            // tslint:enable
        };

        private readonly railwaySeasonTicketGraph = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    y: [  14,       4,       3,         3,        2],
                    x: ["UK", "Spain", "Italy", "Germany", "France"],
                    orientation: 'v',
                    marker: {
                        color: ['hsl(0, 100%, 35%)', 'hsl(70, 100%, 35%)', 'hsl(90, 100%, 35%)', 'hsl(90, 100%, 35%)', 'hsl(120, 100%, 35%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly liquidPricesGraph = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: ["Tap<br>water", "Milk", "Mineral<br>Water", "Coco-Cola", "Petrol",   "Beer"],
                    y: [         0.001,   0.59,               0.65,        1.25,     1.45,     2.27],
                    marker: {
                        color: ['hsl(240, 20%, 65%)', 'hsl(60, 50%, 75%)', 'hsl(240, 20%, 65%)', 'hsl(20, 100%, 15%)', 'hsl(65, 65%, 60%)', 'hsl(40, 100%, 40%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly iPhonePriceGraph = {
            // tslint:disable
            traces: [
                {
                    type:           "bar",
                    name:           "Minimum Price",
                    orientation:    'h',
                    x: [499,
                        499,
                        649,
                        649,
                        649,
                        649,
                        699,
                        999,
                        699,
                        649],
                    y: ["iPhone<br>2007",
                        "iPhone 3G<br>2008",
                        "iPhone 4<br>2010",
                        "iPhone 5<br>2012",
                        "iPhone 6<br>2014",
                        "iPhone 7<br>2016",
                        "iPhone 8<br>2017",
                        "iPhone X<br>2017",
                        "iPhone 11<br>2019",
                        "iPhone 12<br>2020"],
                    marker: {
                        color: 'hsl(210, 100%, 45%)'
                    },
                },
                {
                    type:           "bar",
                    name:           "Maximum Price",
                    orientation:    'h',
                    x: [0,
                        0,
                        100,
                        200,
                        200,
                        200,
                        150,
                        150,
                        250,
                        100],
                    y: ["iPhone<br>2007",
                        "iPhone 3G<br>2008",
                        "iPhone 4<br>2010",
                        "iPhone 5<br>2012",
                        "iPhone 6<br>2014",
                        "iPhone 7<br>2016",
                        "iPhone 8<br>2017",
                        "iPhone X<br>2017",
                        "iPhone 11<br>2019",
                        "iPhone 12<br>2020"],
                    marker: {
                        color: 'hsl(30, 100%, 50%)'
                    },
                }
            ],
            // tslint:enable
        };

        private readonly iPhonePriceGraph2 = {
            // tslint:disable
            traces: [
                {
                    type:           "scatter",
                    name:           "Maximum Price",
                    orientation:    'v',                    
                    x: ["2007",
                        "2008",
                        "2010",
                        "2012",
                        "2014",
                        "2016",
                        "2017",
                        "2017",
                        "2019",
                        "2020"],
                    y: [499,
                        499,
                        749,
                        849,
                        849,
                        849,
                        849,
                        1249,
                        949,
                        749],
                    marker: {
                        color: 'hsl(0, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(20, 50%, 75%)',
                        width: 2
                    },
                },
                {
                    type:           "scatter",
                    name:           "Minimum Price",
                    orientation:    'v',
                    x: ["2007",
                        "2008",
                        "2010",
                        "2012",
                        "2014",
                        "2016",
                        "2017",
                        "2017",
                        "2019",
                        "2020"],
                    y: [499,
                        499,
                        649,
                        649,
                        649,
                        649,
                        699,
                        999,
                        699,
                        649],
                    marker: {
                        color: 'hsl(240, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(220, 50%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly averageUkRent = {
            // tslint:disable
            traces: [
                {
                    type: "scatter",
                    x: ["2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019"],
                    y: [   612,    624,    640,    656,    652,    704,    716,    736,    768,    772,    800],
                    marker: {
                        color: ['hsl(120, 100%, 50%)',
                                'hsl(110, 100%, 50%)',
                                'hsl(100, 100%, 50%)',
                                'hsl(90, 100%, 50%)',
                                'hsl(95, 100%, 50%)',
                                'hsl(75, 100%, 50%)',
                                'hsl(55, 100%, 50%)',
                                'hsl(35, 100%, 50%)',
                                'hsl(25, 100%, 50%)',
                                'hsl(15, 100%, 50%)',
                                'hsl(0, 100%, 50%)'],
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly tuitionFees = {
            // tslint:disable
            traces: [
                {
                    type: "scatter",
                    x: ["2001", "2003", "2009", "2010", "2016"],
                    y: [  1000,   3000,   3290,   9000,   9250],
                    marker: {
                        color: ['hsl(120, 100%, 50%)', 'hsl(95, 100%, 50%)', 'hsl(80, 100%, 50%)', 'hsl(30, 100%, 50%)', 'hsl(0, 100%, 50%)'],
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private get createRecessionLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "Gross Domestic Product Percentage", 72, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createGDPLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "", 32, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createiPhoneLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("£ Price", "", 96, 48);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            layout["barmode"]       = "stack";
            // tslint:enable
            return layout;
        }

        private get createiPhoneLayout2(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "£ Price", 72, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createRailwayLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "Percentage of Salary", 72, 16);
            // tslint:enable
        }

        private get createLiquidLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "£ per litre", 72, 16);
            // tslint:enable
        }

        private get createTaxationLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "Debt Percentage", 72, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createRentLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "£ Monthly rent", 72, 16);
            // tslint:enable
        }

        private get createTuitionLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "£ Annual cost", 72, 16);
            // tslint:enable
        }

        private data() {
            return {
                economyIconPathAndName:
                    require("@/assets/images/icon_economy.png"),

                salaryIconPathAndName:
                    require("@/assets/images/icon_salary.png"),
            };
        }
    }
